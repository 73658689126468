<template>
  <div class="right">
    <span class="spen">退换货</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Tuihuo' }" tag="li">退换货</router-link>
          <router-link :to="{ name: 'Jilu' }" tag="li">退换货记录</router-link>
          <router-link :to="{ name: 'Mingxi' }" tag="li">退款明细</router-link>
        </ul>
      </div>
      <!-- <div class="search">
        <input type="text" v-model="mes" placeholder="输入商品名称订单号" />
        <div class="fangdajing">
          <img src="../../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div> -->
    </div>
    <Loading v-if="isLoading" />
    <div v-else>
      <div class="top">
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="returnCode" label="退换货编号">
          </el-table-column>
          <el-table-column prop="orderNo" label="订单编号"> </el-table-column>
          <el-table-column prop="skuName" label="商品名称" width="170">
          </el-table-column>
          <el-table-column prop="applyTime" label="申请时间" width="170">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="120">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template>
              <!-- <el-button type="text" size="small">查看</el-button> -->
              <el-button type="text" size="small" @click="mingxi"
                >退款明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="fenye" v-if="list != ''">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="totalNum"
          @current-change="pagechange"
          :current-page.sync="currentPage"
          v-if="totalNum != 0"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { setContextData, getContextData } from '../../../../utils/session.js';
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      tableData: [],
      list: [],
      isLoading: true,
      currentPage: 1,
      totalNum: 1,
    };
  },
  created() {
    this.currentPage = getContextData('currentPage8') || 1;
    this.getorder(this.currentPage);
  },
  methods: {
    mingxi() {
      this.$router.push({
        name: 'Mingxi',
      });
    },
    pagechange(p) {
      this.currentPage = p;
      setContextData('currentPage8', this.currentPage);
      get(
        'api/orderreturn/getapplyorderlist?pageNum=' + p + '&pageSize=10'
      ).then((res) => {
        this.list = res.data.data.list;
      });
    },
    getorder(e) {
      get(
        'api/orderreturn/getapplyorderlist?pageNum=' + e + '&pageSize=10'
      ).then((res) => {
        this.isLoading = false;
        res.data.data.list.forEach((res) => {
          if (res.status == 0) {
            res.status = '退款中';
          } else {
            res.status = '退换完成';
          }
        });
        this.list = res.data.data.list;
        this.totalNum = res.data.data.total;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .qiehuan {
    //   height: 80px;
    //   display: flex;
    //   align-items: center;
    //   cursor: default;
    //   .daohang {
    //     font-size: 15px;
    //     color: #272727;
    //     margin-right: 25px;
    //     cursor: pointer;
    //   }
    //   .bian {
    //     color: #1a4fff;
    //   }
    // }
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;

      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 250px;
        height: 32px;
        line-height: 40px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    // .search {
    //   margin-right: 150px;
    //   display: flex;
    //   input {
    //     border: 0;
    //     outline: none;
    //     border: 1px solid #a7a7a7;
    //     height: 37px;
    //     padding-left: 10px;
    //   }
    //   .fangdajing {
    //     width: 45px;
    //     height: 40px;
    //     border: 1px solid #a7a7a7;
    //     border-left: none;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }
    // }
  }
  .top {
    margin-top: 30px;
  }
}
</style>
